import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SanityImage from "gatsby-plugin-sanity-image";
import { ProductType, ProductsType } from "./types";
import { Link } from "../Link";
import { Translations } from "../../utils/algoliaProductsQuery";
import { getLocalizedPath } from "../../utils/gatsbyBuild";
import { LocalizedContext } from "../../services/LocalizedContextService";
import "./styles.scss";
import ProductRatings from "../ProductRatings";
import { event12 } from "../../analytics/event12";

const PopularProducts = (props: ProductsType) => {
  const { sanitySiteSettings } = useContext(LocalizedContext);
  const { language = "en", heading, products = [], showTotalCount = false } = props;
  return (
    <>
      {products.length && (
        <div className="rx-product-listing--popular">
          <Container fluid>
            <Row>
              <Col sm={12} lg={{ span: 10, offset: 1 }}>
                <div className="rx-product-listing--wrapper">
                  {showTotalCount ? (
                    <h2 className="rx-product-listing--title text-left mb-2">{`${products.length} ${heading}`}</h2>
                  ) : (
                    <h2 className="rx-product-listing--title">{heading}</h2>
                  )}
                  <div className="ais-InfiniteHits">
                    <ul className="ais-InfiniteHits-list">
                      {products.map((node: ProductType, index) => {
                        const {
                          _id,
                          slug: { current },
                          images,
                          subBrand,
                          name,
                          smartProductId,
                          paSmartProductId,
                          paUPC
                        } = node;

                        const onClick = () =>
                          event12(
                            _id,
                            name,
                            subBrand ? subBrand.name : "",
                            sanitySiteSettings.brandName,
                            index,
                            "Popular Product Listing"
                          );
                        return (
                          <li className="ais-InfiniteHits-item" key={_id}>
                            <div className="ais-InfiniteHits-item__custom row">
                              <div className="ais-InfiniteHits-item__copy col-lg-7 col-12">
                                <div className="hit-compv2">
                                  <Link
                                    className="ais-InfiniteHits-item__link"
                                    to={getLocalizedPath(`/${Translations[language].products}/${current}/`, _id)}
                                    onClick={onClick}
                                  >
                                    <SanityImage
                                      {...images[0]}
                                      sizes="(min-width: 768px) 303px 294px"
                                      width={303}
                                      height={294}
                                      className={``}
                                    />
                                    <div className="rx-product-listing--item-content">
                                      <span className="ais-InfiniteHits-item_range">{subBrand && subBrand.name}</span>
                                      <h3 className="ais-InfiniteHits-item_title">
                                        <span className="ais-Highlight">
                                          <span className="ais-Highlight-nonHighlighted">{name}</span>
                                        </span>
                                      </h3>
                                    </div>
                                    <ProductRatings
                                      upc={smartProductId || paSmartProductId || paUPC}
                                      productName={name}
                                      template="pdp"
                                    />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default PopularProducts;
