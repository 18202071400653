export const Translations = {
    en: {
        bestSeller: "best-seller",
        men: "men",
        women: "women",
        neutral: "gender neutral",
        products: "products",
        spray: "spray"
    },
    es: {
        bestSeller: "mejor-vendido",
        men: "hombres",
        women: "mujeres",
        products: "productos",
        spray: "spray"
    },
    pt: {
        bestSeller: "mais-vendidos",
        men: "masculino",
        women: "feminino",
        products: "produtos",
        spray: "aerosol"
    }
}